(function (document, NodeList) {
	var l10nDropdown2Fill = document.getElementById('l10n-dropdown-to-fill');
	// var l10nDropdownRequested = document.getElementById('l10n-dropdown-requested');
	var criteresLocalisation = document.getElementById('criteres-localisation');
	var criteresLocalisationList = [];
	var formSearchQuartier = document.forms.searchBandeau['quartier[]'];
	var formSearchVille = document.forms.searchBandeau['ville[]'];
	var searchInput = document.querySelector('#search_lieu');

	var typebienDropdown = document.querySelectorAll('#typebien .false-select-content .false-select-dropdown .false-select-option');
	var criteresTypebien = document.getElementById('criteres-typebien');
	var criteresTypebienList = [];

	var nbPiecesDropdown = document.querySelectorAll('#pieces .false-select-content .false-select-dropdown .false-select-option');
	var criteresNbPieces = document.getElementById('criteres-nbpieces');
	var criteresNbPiecesList = [];

	var budgetMaxInput = document.querySelector('#budget .false-select-content .false-select-dropdown .false-select-option input');
	var criteresBudgetMax = document.getElementById('criteres-budgetmax');

	var rayonDropdown = document.querySelectorAll('#rayon .false-select-content .false-select-dropdown .false-select-option');
	var criteresRayon = document.getElementById('criteres-rayon');
	var criteresRayonList = [];

	var equipementsDropdown = document.querySelectorAll('#equipements .false-select-content .false-select-dropdown .false-select-option');
	var criteresEquipements = document.getElementById('criteres-equipements');
	var criteresEquipementsList = [];

	var agencesDropdown = document.querySelectorAll('#agences .false-select-content .false-select-dropdown .false-select-option');
	var criteresAgences = document.getElementById('criteres-agences');
	var criteresAgencesList = [];

	let visiteVirtuelleContainer = document.getElementById('visite_virtuelle');
	let visiteVirtuelleInput = document.getElementById('avec_visite_virtuelle');


	// construction du nœud de chaque localisation
	function addNodeLocalisation(type, value, textLabel, is_checked = false) {
		var div = document.createElement('div');
		div.className = 'false-select-option';

		var checkbox = document.createElement('input');
		var id = type + '_' + value;
		checkbox.type = "checkbox";
		checkbox.id = id;
		checkbox.name = type + "[]";
		checkbox.value = value;
		checkbox.setAttribute('data-requested', false);
		if (is_checked) {
			checkbox.setAttribute('checked', true);
		}
		checkbox.addEventListener('change', function () {
			if (this.checked) {
				criteresLocalisationList.push(textLabel);
			} else {
				var index = criteresLocalisationList.indexOf(textLabel);
				if (index > -1) {
					criteresLocalisationList.splice(index, 1);
				}
			}
			if (criteresLocalisationList.length > 0) {
				criteresLocalisationList.sort();
				criteresLocalisation.innerHTML = criteresLocalisationList.join(', ');
			} else {
				criteresLocalisation.innerHTML = '-'
			}
		});

		var label = document.createElement('label');
		label.htmlFor = id;
		label.appendChild(document.createTextNode(textLabel));

		div.appendChild(checkbox);
		div.appendChild(label);

		return div;
	}

	function addNodeFalseOptgroup(text) {
		let span = document.createElement('span');
		span.className = 'false-select-optgroup';
		span.appendChild(document.createTextNode(text));
		return span;
	}

	// travail sur le dropdown des localisations rempli via ajax
	if (undefined !== l10nDropdown2Fill) {

		searchInput.addEventListener('keyup', function () {
			if (this.value.length > 2) {
				// formSearchQuartier = document.forms.searchBandeau['quartier[]'];
				// formSearchVille = document.forms.searchBandeau['ville[]'];



				var request = new XMLHttpRequest();
				request.open('POST', l10nSearchAjax.ajaxurl + "?action=l10n_completion&security=" + l10nSearchAjax.security + "&q=" + this.value, true);
				request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
				request.onload = function () {

					if (200 === this.status) {
						var response = JSON.parse(this.response),
							htmlFalseOptionsV = [],
							htmlFalseOptionsQ = [],
							quartier = [],
							ville = [];

						if (undefined !== formSearchQuartier) {
							if (NodeList.prototype.isPrototypeOf(formSearchQuartier)) {
								formSearchQuartier.forEach(
									function (currentValue) {
										if ('true' === currentValue.dataset.requested) {
											quartier.push(currentValue.value);
											htmlFalseOptionsQ.push(addNodeLocalisation('quartier', currentValue.value, currentValue.parentNode.querySelector("label[for='" + currentValue.id + "']").textContent, true))
										} else if (currentValue.checked) {
											htmlFalseOptionsQ.push(addNodeLocalisation('quartier', currentValue.value, currentValue.labels[0].textContent, true));
										}
									}
								);
							} else if ('true' === formSearchQuartier.dataset.requested) {
								quartier.push(formSearchQuartier.value);
								htmlFalseOptionsQ.push(addNodeLocalisation('quartier', formSearchQuartier.value, formSearchQuartier.parentNode.querySelector("label[for='" + formSearchQuartier.id + "']").textContent, true));
							} else if (formSearchQuartier.checked) {
								htmlFalseOptionsQ.push(addNodeLocalisation('quartier', formSearchQuartier.value, formSearchQuartier.labels[0].textContent, true));
							}
						}
						if (undefined !== formSearchVille) {
							if (NodeList.prototype.isPrototypeOf(formSearchVille)) {
								formSearchVille.forEach(
									function (currentValue) {
										if ('true' === currentValue.dataset.requested) {
											ville.push(currentValue.value);
											htmlFalseOptionsV.push(addNodeLocalisation('ville', currentValue.value, currentValue.parentNode.querySelector("label[for='" + currentValue.id + "']").textContent, true))
										} else if (currentValue.checked) {
											htmlFalseOptionsV.push(addNodeLocalisation('ville', currentValue.value, currentValue.labels[0].textContent, true));
										}
									}
								);
							} else if ('true' === formSearchVille.dataset.requested) {
								ville.push(formSearchVille.value);
								htmlFalseOptionsV.push(addNodeLocalisation('ville', formSearchVille.value, formSearchVille.parentNode.querySelector("label[for='" + formSearchVille.id + "']").textContent, true));
							} else if (formSearchVille.checked) {
								htmlFalseOptionsV.push(addNodeLocalisation('ville', formSearchVille.value, formSearchVille.labels[0].textContent, true));
							}
						}
						Object.keys(response.ville).forEach(function (key) {
							if (-1 === ville.indexOf(key)) {
								htmlFalseOptionsV.push(addNodeLocalisation('ville', key, response.ville[key], false));
							}
						});
						Object.keys(response.quartier).forEach(function (key) {
							if (-1 === quartier.indexOf(key)) {
								htmlFalseOptionsQ.push(addNodeLocalisation('quartier', key, response.quartier[key], false));
							}
						});

						l10nDropdown2Fill.innerHTML = '';
						if (htmlFalseOptionsV.length > 0) {

							l10nDropdown2Fill.appendChild(addNodeFalseOptgroup('Villes'));

							htmlFalseOptionsV.forEach(function (node) {
								l10nDropdown2Fill.appendChild(node);
							});

						}
						if (htmlFalseOptionsQ.length > 0) {

							l10nDropdown2Fill.appendChild(addNodeFalseOptgroup('Quartiers'));

							htmlFalseOptionsQ.forEach(function (node) {
								l10nDropdown2Fill.appendChild(node);
							});
						}
					} else {
						console.log('fail | status : ' + this.status + ' | response : ' + this.response);
					}
				};

				request.onerror = function () {
					console.log('connection error');
				};

				request.send();
			}
		});
	}

	// travail sur le dropdown des localisations remplies via PHP
	// if (undefined !== l10nDropdownRequested) {
	// 	var divsL10nDropdownRequested = l10nDropdownRequested.querySelectorAll('div');

	// 	if (NodeList.prototype.isPrototypeOf(divsL10nDropdownRequested)) {

	// 		divsL10nDropdownRequested.forEach(function (node) {
	// 			var input = node.querySelector('input');
	// 			var label = node.querySelector('label');

	// 			// init
	// 			criteresLocalisationList.push(label.textContent)

	// 			// event
	// 			input.addEventListener("change", function () {
	// 				if (this.checked) {
	// 					criteresLocalisationList.push(label.textContent);
	// 				} else {
	// 					var index = criteresLocalisationList.indexOf(label.textContent);
	// 					if (index > -1) {
	// 						criteresLocalisationList.splice(index, 1);
	// 					}
	// 				}
	// 				criteresLocalisationList.sort();
	// 				if (criteresLocalisationList.length > 0) {
	// 					criteresLocalisation.innerHTML = criteresLocalisationList.join(', ');
	// 				} else {
	// 					criteresLocalisation.innerHTML = '-';
	// 				}
	// 			});
	// 		});
	// 	}
	// }

	// type de bien
	if (undefined !== typebienDropdown) {
		for (const node of typebienDropdown) {
			const input = node.querySelector('input');
			const label = node.querySelector('label');

			// init
			if (input.checked) {
				criteresTypebienList.push(label.textContent)
			}

			// event
			input.addEventListener("change", function () {
				if (this.checked) {
					criteresTypebienList.push(label.textContent);
				} else {
					var index = criteresTypebienList.indexOf(label.textContent);
					if (index > -1) {
						criteresTypebienList.splice(index, 1);
					}
				}
				if (criteresTypebienList.length > 0) {
					criteresTypebienList.sort();
					criteresTypebien.innerHTML = criteresTypebienList.join(', ');
				} else {
					criteresTypebien.innerHTML = '-';
				}
			});
		}
	}

	// nb de pièces
	if (undefined !== nbPiecesDropdown) {
		for (const node of nbPiecesDropdown) {
			const input = node.querySelector('input');
			const inputVal = input.value;
			const label = '5' === inputVal ? '5+' : inputVal;

			// init
			if (input.checked) {
				criteresNbPiecesList.push(label);
			}

			// event
			input.addEventListener("change", function () {
				if (this.checked) {
					criteresNbPiecesList.push(label);
				} else {
					var index = criteresNbPiecesList.indexOf(label);
					if (index > -1) {
						criteresNbPiecesList.splice(index, 1);
					}
				}
				if (criteresNbPiecesList.length > 0) {
					criteresTypebienList.sort();
					criteresNbPieces.innerHTML = criteresNbPiecesList.join(', ');
				} else {
					criteresNbPieces.innerHTML = '-';
				}
			});
		}
	}

	// budget
	/*if (undefined !== budgetMaxInput) {
		budgetMaxInput.addEventListener("change", function() {
			if ( budgetMaxInput.value > 0 ) {
				criteresBudgetMax.innerHTML = budgetMaxInput.value;
			} else {
				criteresBudgetMax.innerHTML = '-';
			}
		});
	}*/

	// rayon
	if (undefined !== rayonDropdown) {
		for (const node of rayonDropdown) {
			const input = node.querySelector('input');
			const label = node.querySelector('label');

			// event
			input.addEventListener("change", function () {
				criteresRayon.innerHTML = label.textContent;
			});
		}
	}

	// equpêments
	if (undefined !== equipementsDropdown) {
		for (const node of equipementsDropdown) {
			const input = node.querySelector('input');
			const label = node.querySelector('label');

			// init
			if (input.checked) {
				criteresEquipementsList.push(label.textContent)
			}

			// event
			input.addEventListener("change", function () {
				if (this.checked) {
					criteresEquipementsList.push(label.textContent);
				} else {
					var index = criteresEquipementsList.indexOf(label.textContent);
					if (index > -1) {
						criteresEquipementsList.splice(index, 1);
					}
				}
				if (criteresEquipementsList.length > 0) {
					criteresEquipementsList.sort();
					criteresEquipements.innerHTML = criteresEquipementsList.join(', ');
				} else {
					criteresEquipements.innerHTML = '-';
				}
			});
		}
	}

	// agences
	if (undefined !== agencesDropdown) {
		for (const node of agencesDropdown) {
			const input = node.querySelector('input');
			const label = node.querySelector('label');

			// init
			if (input.checked) {
				criteresAgencesList.push(label.textContent)
			}

			// event
			input.addEventListener("change", function () {
				if (this.checked) {
					criteresAgencesList.push(label.textContent);
				} else {
					var index = criteresAgencesList.indexOf(label.textContent);
					if (index > -1) {
						criteresAgencesList.splice(index, 1);
					}
				}
				if (criteresAgencesList.length > 0) {
					criteresAgencesList.sort();
					criteresAgences.innerHTML = criteresAgencesList.join(', ');
				} else {
					criteresAgences.innerHTML = '-';
				}
			});
		}
	}

	// Gestion de la perte du focus sur les faux select (bandeau de recherche)
	document.addEventListener('click', function (e) {
		if (!e.target.closest('.false-select')) {
			removeFocusFalseSelect();
		}
	});

	function removeFocusFalseSelect() {
		var closer = document.getElementById('select-closer');
		if (closer) {
			closer.checked = true;
		}
	}

	// Switcher - Onglets
	// Random input search name
	document.addEventListener('DOMContentLoaded', function () {
		var switcher = document.querySelector('.form-onglets');
		if (switcher) {
			switcher.addEventListener('click', function () {
				if (!document.body.classList.contains('home') || (document.body.classList.contains('home') && window.innerWidth < 960)) {
					switcher.classList.toggle('open');
				}
			});
		}

		var nbResultats = document.getElementById('nombre-resultats'),
			contTri = document.getElementById('tri-container');
		// console.log('chargé ?');
		// console.log(contTri);
		// console.log(nbResultats);
		if (nbResultats && contTri) {
			// console.log('in ?');
			var nbResList = document.getElementById('nombre-resultats-tri');
			nbResList.innerHTML = nbResultats.innerHTML;
			nbResultats.setAttribute('hidden', true);
		}

		// Random input search name
		searchInput.name = random_uniq_string();
	});

	//generates random string
	function random_uniq_string() {
		return Math.floor((1 + Math.random()) * 0x100000000).toString(16).substring(1);
	}

	// document.forms.searchBandeau.addEventListener('submit', function(e) {
	// 	e.preventDefault();
	// 	searchInput.removeAttribute('name');
	// 	document.forms.searchBandeau.submit();
	// });

	// Gezstion de l'affichage de l'agence de Gestion Location
	let postType = document.getElementsByName('post_type');
	let serviceLocationGestion = document.getElementById('agence_8');

	function serviceLocationChange(e) {
		if (e.target.checked && e.target.value === 'achat') {
			serviceLocationGestion.parentNode.classList.add('hidden');
		} else {
			serviceLocationGestion.parentNode.classList.remove('hidden');
		}
	}

	if (null !== serviceLocationGestion) {
		for (let i = 0; i < postType.length; i++) {
			postType[i].addEventListener('change', serviceLocationChange);
			if (postType[i].checked) {
				if (postType[i].value === 'achat') {
					serviceLocationGestion.parentNode.classList.add('hidden');
				} else {
					serviceLocationGestion.parentNode.classList.remove('hidden');
				}
			}
		}
	}

	// Visite virtuelle
	if (visiteVirtuelleContainer !== null) {
		visiteVirtuelleContainer.addEventListener('click', changeContainerStyle);
	}

	function changeContainerStyle() {
		if (visiteVirtuelleInput.checked) {
			visiteVirtuelleInput.checked = false;
			visiteVirtuelleContainer.classList.remove('isChecked');
		} else {
			visiteVirtuelleInput.checked = true;
			visiteVirtuelleContainer.classList.add('isChecked');
		}
	}
	// FIN Visite virtuelle
})(document, NodeList);